import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from ".//../images/logosupport2.png"
import Menu  from ".//menu/nav"
import { sg_logo, sg_textlogo, sg_header, sg_leftmenu, overlay, overlay_content,closebtn }  from "./index.module.css"


const openNav = () => {
  document.getElementById("myNav").style.width = "100%";
  document.getElementById("myNav").style.Zindex = "3";
}

const closeNav= () => {
  document.getElementById("myNav").style.width = "0%";
}

const Header = ({ siteTitle }) => (
  <header className={sg_header}>
  <span
      className={closebtn}
      onClick={openNav}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          openNav();
        }
      }}
      role="button"
      tabIndex="0"
  >
  &#9776;
</span>
  <div id="myNav"className={overlay}>
    <Link to="/" style={{fontSize:`50px`,marginTop:`-16px`}} className={closebtn} onClick={closeNav}>&times;</Link>
    <div className={overlay_content}>
      <Link to="/">صفحه اصلی</Link>
      <Link to="https://supportwebs.ir">پشتیبان وب</Link>
      <Link to="/portable">پورتابل</Link>
      <Link to="/">تماس با ما</Link>
    </div>
  </div>
      
       
    <div className={sg_logo}>
    <Link
      to="/"
      style={{
        fontSize: `var(--font-sm)`,
        textDecoration: `none`,
      }}
    >
    <img
      alt="پشتیبان گروپ"
      height={35}
      style={{ margin: 0 }}
      src={logo}
      />    
    </Link>

    <Link
      to="/"
      className={sg_textlogo}
    >
    پشتیبان <span style={{color:`#55BBCC`}}> گروپ</span>
    </Link>

    </div>
    { /*menu part */ }
    <Menu />
    
    { /* left menu */ }
    <div className={sg_leftmenu}>
    
    <Link
      to="/portable"
    >
      پرتابل
    </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
