// extracted by mini-css-extract-plugin
export var alarm = "index-module--alarm--iZ8GV";
export var closebtn = "index-module--closebtn--j8TQr";
export var dropbtn = "index-module--dropbtn--H8LC0";
export var dropdown = "index-module--dropdown--XKMlz";
export var dropdown_content = "index-module--dropdown_content--DTCw7";
export var fadeIn = "index-module--fadeIn--4P+K7";
export var headbody = "index-module--headbody--cT9Di";
export var info_user = "index-module--info_user--WIXHF";
export var intro = "index-module--intro--C4dpM";
export var list = "index-module--list--+0B75";
export var listItem = "index-module--listItem--BubbZ";
export var listItemDescription = "index-module--listItemDescription--84dWA";
export var listItemLink = "index-module--listItemLink--Asns5";
export var navbar = "index-module--navbar---a9tG";
export var one = "index-module--one--rso4Y";
export var overlay = "index-module--overlay--5lATv";
export var overlay_content = "index-module--overlay_content--5HCVJ";
export var sgBotton = "index-module--sgBotton--AruP5";
export var sg_background_main = "index-module--sg_background_main--KMov4";
export var sg_background_part2 = "index-module--sg_background_part2--bcQHs";
export var sg_background_part3 = "index-module--sg_background_part3--n9ttr";
export var sg_background_part4 = "index-module--sg_background_part4--1YXhe";
export var sg_bghome1 = "index-module--sg_bghome1--DiHZG";
export var sg_bghome2 = "index-module--sg_bghome2--zVjBZ";
export var sg_bghome3 = "index-module--sg_bghome3--QAYxc";
export var sg_bghome4 = "index-module--sg_bghome4--IOYCW";
export var sg_bottom_chatgpt = "index-module--sg_bottom_chatgpt--QkEBv";
export var sg_botton_form = "index-module--sg_botton_form--qhU5c";
export var sg_center_text = "index-module--sg_center_text--GbUeK";
export var sg_contactform = "index-module--sg_contactform---71rG";
export var sg_content_container = "index-module--sg_content_container--lQM3-";
export var sg_content_page1 = "index-module--sg_content_page1---1OQu";
export var sg_content_panel = "index-module--sg_content_panel--ILfC7";
export var sg_disinblock = "index-module--sg_disinblock--Hdw6G";
export var sg_email = "index-module--sg_email--+KsBe";
export var sg_footer = "index-module--sg_footer--CiynA";
export var sg_form_chatgpt = "index-module--sg_form_chatgpt--Gra6T";
export var sg_form_design = "index-module--sg_form_design--oKCki";
export var sg_header = "index-module--sg_header--I5jUH";
export var sg_icon_message = "index-module--sg_icon_message--D58Hy";
export var sg_input_chatgpt = "index-module--sg_input_chatgpt--iLI94";
export var sg_input_design = "index-module--sg_input_design--tm2h2";
export var sg_input_header = "index-module--sg_input_header--3rJpG";
export var sg_label_design = "index-module--sg_label_design--cedHc";
export var sg_leftmenu = "index-module--sg_leftmenu--cAUS-";
export var sg_logo = "index-module--sg_logo--nd3TQ";
export var sg_logo_sidebar = "index-module--sg_logo_sidebar--wA2jM";
export var sg_mapform = "index-module--sg_mapform--BQy0w";
export var sg_mapimg = "index-module--sg_mapimg--XuhgP";
export var sg_maptext = "index-module--sg_maptext--GErIZ";
export var sg_menu = "index-module--sg_menu--oUrMs";
export var sg_message_sidebar = "index-module--sg_message_sidebar--sOCIF";
export var sg_panel_home = "index-module--sg_panel_home--lxXYI";
export var sg_phone = "index-module--sg_phone--H-kgt";
export var sg_text_center = "index-module--sg_text_center--FaG2M";
export var sg_textlogo = "index-module--sg_textlogo--GygBM";
export var sg_version = "index-module--sg_version--g2jre";
export var sg_whatapp = "index-module--sg_whatapp--RM1oq";
export var sg_whatapp_text = "index-module--sg_whatapp_text--fDTaW";
export var sgsidebar = "index-module--sgsidebar--ANaQe";
export var sidebar_head = "index-module--sidebar_head---rcPL";
export var stars = "index-module--stars--3Ce5x";
export var textCenter = "index-module--textCenter--Ts42-";
export var there = "index-module--there--Ot-Th";
export var two = "index-module--two--d3it6";