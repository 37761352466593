import * as React from "react"

import { Link } from "gatsby"
import { sg_menu }  from ".//../index.module.css"

const Menu = () => {
  return(
    <>
  <div className={sg_menu}>
    <Link to="/">صفحه اصلی</Link>
    <Link to="https://supportwebs.ir" className={sg_menu}>پشتیبان وب</Link>
      <Link to="#" className={sg_menu}>درباره ما</Link>
      <Link to="#" className={sg_menu}>تماس با ما</Link>
 </div>
 </>
)
}
export default Menu