import * as React from "react"
import { sg_footer, sg_email, sg_phone} from "./index.module.css"
import { Link } from "gatsby"

const Footer = () => {
	return(
		<>
			<div className={sg_footer}>
				<div >
					<Link to="mailto:info@supportwebs.ir" className={sg_email}>info@supportwebs.ir</Link>
					
				</div>
				<div >
				<Link to="tel:02188628634" className={sg_phone}>021-88628634</Link>
				</div>
			</div>
		</>
		)

}

export default Footer
